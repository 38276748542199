import React from "react"
import SEO from "../components/seo"
import {
  Layout,
  AboutMe,
  SectionTitle,
  Services,
  Experiences,
  Projects,
} from "../components/index"
import { data } from "../data"
import indexStyles from "../styles/index.module.css"
import { FaLinkedin, FaEnvelope, FaMap } from "react-icons/fa"

const Skills = () => (
  <div className={indexStyles.section} id="skills">
    <div className={indexStyles.secondSection}>
      <SectionTitle
        title="Professional Skills"
        desc={`I'm a Senior Mobile/Web App Developer specializing in front end
          development. I have experience with all stages of the development cycle
          for dynamic web/mobile projects. I'm well-versed in numerous
          technologies including JavaScript, TypeScript and NodeJS. I have a
          strong background in project management and customer relations.
      `}
      />
      <div className={indexStyles.skillBox}>
        <div className={indexStyles.skillItem}>
          {data.skills.slice(0, 3).map(skillItem => (
            <div
              className={indexStyles.column}
              style={{ width: "100%", margin: "10px" }}
              key={skillItem.title}
            >
              <span style={{ color: "#fff" }}>{skillItem.title}</span>
              <div className={indexStyles.progressBarWrapper}>
                <div
                  className={indexStyles.progressBar}
                  style={{ width: skillItem.rating }}
                />
              </div>
            </div>
          ))}
        </div>
        <div className={indexStyles.skillItem}>
          {data.skills.slice(3, 6).map(skillItem => (
            <div
              className={indexStyles.column}
              style={{ width: "100%", margin: "10px" }}
              key={skillItem.title}
            >
              <span style={{ color: "#fff" }}>{skillItem.title}</span>
              <div className={indexStyles.progressBarWrapper}>
                <div
                  className={indexStyles.progressBar}
                  style={{ width: skillItem.rating }}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  </div>
)

const Education = () => {
  const { education } = data
  return (
    <div className={indexStyles.section} id="education">
      <SectionTitle
        title="Education"
        desc={`I'm a Computer Science graduate and possess a bachelors degree in the
        field of Engineering specializing in Information Technology. I had
        Science and Computers as majors in High School.
      `}
      />
      <div className={indexStyles.experienceContainer}>
        <div className={indexStyles.experienceItem}>
          <div className={indexStyles.expBox}>
            <h3>{education[0].degree}</h3>
            <p className={indexStyles.expDuration}>({education[0].duration})</p>
            <h4>{education[0].institution}</h4>
            <h4 className={indexStyles.boxSubTitle}>{education[0].location}</h4>
          </div>
        </div>
        <div style={{ width: "10%" }} />
        <div className={`${indexStyles.experienceItem} ${indexStyles.expGap}`}>
          <div className={indexStyles.expBox}>
            <h3>{education[1].degree}</h3>
            <p className={indexStyles.expDuration}>({education[1].duration})</p>
            <h4>{education[1].institution}</h4>
            <h4 className={indexStyles.boxSubTitle}>{education[1].location}</h4>
          </div>
        </div>
      </div>
    </div>
  )
}

const Contacts = () => (
  <div
    className={indexStyles.section}
    style={{ backgroundColor: "#f3f5ff", marginTop: "60px" }}
    id="contacts"
  >
    <SectionTitle
      title="Contact Me"
      desc={` I'm available to work as a freelancer. You can reach me at any of
        the following.
      `}
    />
    <div className={indexStyles.contactInfo}>
      <div className={indexStyles.column} style={{ marginRight: "10px" }}>
        <FaEnvelope
          style={{ color: "#4667de", fontSize: "25px", marginBottom: "15px" }}
        />
        <span className={indexStyles.contactTitle}>EMAIL</span>
        <span className={indexStyles.contactDesc}>
          shrutishankar28@gmail.com
        </span>
      </div>
      <div className={indexStyles.column} style={{ marginRight: "10px" }}>
        <FaLinkedin
          style={{ color: "#4667de", fontSize: "25px", marginBottom: "15px" }}
        />
        <span className={indexStyles.contactTitle}>LINKED IN</span>
        <span className={indexStyles.contactDesc}>shruti-shankar-3314b562</span>
      </div>
      <div className={indexStyles.column} style={{ marginRight: "10px" }}>
        <FaMap
          style={{ color: "#4667de", fontSize: "25px", marginBottom: "15px" }}
        />
        <span className={indexStyles.contactTitle}>ADDRESS</span>
        <span className={indexStyles.contactDesc}>Bengaluru, KA, India</span>
      </div>
    </div>
  </div>
)

const IndexPage = () => (
  <Layout>
    <SEO title="shruti shankar" />
    <AboutMe />
    <Skills />
    <Services />
    <Experiences />
    <Projects />
    <Education />
    <Contacts />
  </Layout>
)

export default IndexPage
